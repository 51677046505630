
















import { Vue, Component } from 'vue-property-decorator'
import ResizableInstance from '@/directives/flex-resize/ResizableInstance'
import { uiStore } from '@/store'
import { getDefaultSize } from './utils'

const MinSize = 340
const MaxSize = 420

@Component({
  components: {
    RightBar: () => import('@/components/RightBar/index.vue'),
  },
})
export default class RightDrawer extends Vue {
  private width = MinSize

  private rightResizeOptions = {
    minSize: MinSize,
    defaultSize: getDefaultSize(window.innerWidth, MinSize, MaxSize),
    maxSize: MaxSize,
    zIndex: 1000,
    anchorThickness: 2,
    onSizeChanged: this.onSizeChanged,
    onSizeChanging: this.onSizeChanging,
  }

  onSizeChanged (size: number, context: ResizableInstance) {
    uiStore.mutations.setRightBarData({ size })

    if (!context) return

    const { element } = context
    if (!element) return

    element.style.width = `${size}px`
    this.drawerWidth = size
  }

  onSizeChanging (size: number) {
    this.drawerWidth = size
  }

  get drawerWidth (): number {
    return this.width
  }

  set drawerWidth (value: number) {
    this.width = value
  }
}
